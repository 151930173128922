// SENDSIGNIN
export const SENDSIGNIN_REQUEST = 'SENDSIGNIN_REQUEST'
export const SENDSIGNIN_ERROR = 'SENDSIGNIN_ERROR'
export const SENDSIGNIN_SUCCESS = 'SENDSIGNIN_SUCCESS'

// SENDSIGNUP
export const SENDSIGNUP_REQUEST = 'SENDSIGNUP_REQUEST'
export const SENDSIGNUP_ERROR = 'SENDSIGNUP_ERROR'
export const SENDSIGNUP_SUCCESS = 'SENDSIGNUP_SUCCESS'

// SENDOTP
export const SENDOTP_REQUEST = 'SENDOTP_REQUEST'
export const SENDOTP_ERROR = 'SENDOTP_ERROR'
export const SENDOTP_SUCCESS = 'SENDOTP_SUCCESS'

// USERCHECK AND RESENDTOP
export const USERCHECK_REQUEST = 'USERCHECK_REQUEST'
export const USERCHECK_ERROR = 'USERCHECK_ERROR'
export const USERCHECK_SUCCESS = 'USERCHECK_SUCCESS'

// resetPassword
export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST'
export const RESETPASSWORD_ERROR = 'RESETPASSWORD_ERROR'
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS'

// changePassword
export const CHANGEPASSWORD_REQUEST = 'CHANGEPASSWORD_REQUEST'
export const CHANGEPASSWORD_ERROR = 'CHANGEPASSWORD_ERROR'
export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS'

// getPROFILE
export const GETPROFILE_REQUEST = 'GETPROFILE_REQUEST'
export const GETPROFILE_ERROR = 'GETPROFILE_ERROR'
export const GETPROFILE_SUCCESS = 'GETPROFILE_SUCCESS'

// sentPROFILE
export const UPDATEPROFILE_REQUEST = 'UPDATEPROFILE_REQUEST'
export const UPDATEPROFILE_ERROR = 'UPDATEPROFILE_ERROR'
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS'

// getORDER
export const GETORDER_REQUEST = 'GETORDER_REQUEST'
export const GETORDER_ERROR = 'GETORDER_ERROR'
export const GETORDER_SUCCESS = 'GETORDER_SUCCESS'

// getORDERById
export const GETORDERBYID_REQUEST = 'GETORDERBYID_REQUEST'
export const GETORDERBYID_ERROR = 'GETORDERBYID_ERROR'
export const GETORDERBYID_SUCCESS = 'GETORDERBYID_SUCCESS'

// getWishlist
export const GETWISHLIST_REQUEST= 'GETWISHLIST_REQUEST'
export const GETWISHLIST_ERROR= 'GETWISHLIST_ERROR'
export const GETWISHLIST_SUCCESS= 'GETWISHLIST_SUCCESS'

// createWishlist
export const CREATEWISHLIST_REQUEST= 'CREATEWISHLIST_REQUEST'
export const CREATEWISHLIST_ERROR= 'CREATEWISHLIST_ERROR'
export const CREATEWISHLIST_SUCCESS= 'CREATEWISHLIST_SUCCESS'

// removeWishlist
export const REMOVEWISHLIST_REQUEST= 'REMOVEWISHLIST_REQUEST'
export const REMOVEWISHLIST_ERROR= 'REMOVEWISHLIST_ERROR'
export const REMOVEWISHLIST_SUCCESS= 'REMOVEWISHLIST_SUCCESS'

// existWishlist
export const EXISTWISHLIST_REQUEST= 'EXISTWISHLIST_REQUEST'
export const EXISTWISHLIST_ERROR= 'EXISTWISHLIST_ERROR'
export const EXISTWISHLIST_SUCCESS= 'EXISTWISHLIST_SUCCESS'

// checkProducyQty
export const CHECKPRODUCTQTY_REQUEST= 'CHECKPRODUCTQTY_REQUEST'
export const CHECKPRODUCTQTY_ERROR= 'CHECKPRODUCTQTY_ERROR'
export const CHECKPRODUCTQTY_SUCCESS= 'CHECKPRODUCTQTY_SUCCESS'

// createCart
export const CREATECART_REQUEST= 'CREATECART_REQUEST'
export const CREATECART_ERROR= 'CREATECART_ERROR'
export const CREATECART_SUCCESS= 'CREATECART_SUCCESS'

// getCart
export const GETCART_REQUEST= 'GETCART_REQUEST'
export const GETCART_ERROR= 'GETCART_ERROR'
export const GETCART_SUCCESS= 'GETCART_SUCCESS'

// getTicketCart
export const GETTICKETCART_REQUEST= 'GETTICKETCART_REQUEST'
export const GETTICKETCART_ERROR= 'GETTICKETCART_ERROR'
export const GETTICKETCART_SUCCESS= 'GETTICKETCART_SUCCESS'

// deleteCart
export const DELETECART_REQUEST= 'DELETECART_REQUEST'
export const DELETECART_ERROR= 'DELETECART_ERROR'
export const DELETECART_SUCCESS= 'DELETECART_SUCCESS'

// deleteAllInCart
export const DELETEALLINCART_REQUEST= 'DELETEALLINCART_REQUEST'
export const DELETEALLINCART_ERROR= 'DELETEALLINCART_ERROR'
export const DELETEALLINCART_SUCCESS= 'DELETEALLINCART_SUCCESS'

// createShipping
export const CREATESHIPPING_REQUEST= 'CREATESHIPPING_REQUEST'
export const CREATESHIPPING_ERROR= 'CREATESHIPPING_ERROR'
export const CREATESHIPPING_SUCCESS= 'CREATESHIPPING_SUCCESS'

// getDeliveryShipping
export const GETDELIVERYINFORMATION_REQUEST= 'GETDELIVERYINFORMATION_REQUEST'
export const GETDELIVERYINFORMATION_ERROR= 'GETDELIVERYINFORMATION_ERROR'
export const GETDELIVERYINFORMATION_SUCCESS= 'GETDELIVERYINFORMATION_SUCCESS'

// getPaymentList
export const GETPAYMENTLIST_REQUEST= 'GETPAYMENTLIST_REQUEST'
export const GETPAYMENTLIST_ERROR= 'GETPAYMENTLIST_ERROR'
export const GETPAYMENTLIST_SUCCESS= 'GETPAYMENTLIST_SUCCESS'

// payNow
export const PAYNOW_REQUEST= 'PAYNOW_REQUEST'
export const PAYNOW_ERROR= 'PAYNOW_ERROR'
export const PAYNOW_SUCCESS= 'PAYNOW_SUCCESS'

// location
export const GETCITYLIST_REQUEST= 'GETCITYLIST_REQUEST'
export const GETCITYLIST_ERROR= 'GETCITYLIST_ERROR'
export const GETCITYLIST_SUCCESS= 'GETCITYLIST_SUCCESS'

export const GETCOUNTRYLIST_REQUEST= 'GETCOUNTRYLIST_REQUEST'
export const GETCOUNTRYLIST_ERROR= 'GETCOUNTRYLIST_ERROR'
export const GETCOUNTRYLIST_SUCCESS= 'GETCOUNTRYLIST_SUCCESS'

// GETMYSERVICETICKETSUMMARY
export const GETMYSERVICETICKETSUMMARY_REQUEST = 'GETMYSERVICETICKETSUMMARY_REQUEST'
export const GETMYSERVICETICKETSUMMARY_ERROR = 'GETMYSERVICETICKETSUMMARY_ERROR'
export const GETMYSERVICETICKETSUMMARY_SUCCESS = 'GETMYSERVICETICKETSUMMARY_SUCCESS'

// GETMYSERVICETICKET
export const GETMYSERVICETICKET_REQUEST = 'GETMYSERVICETICKET_REQUEST'
export const GETMYSERVICETICKET_ERROR = 'GETMYSERVICETICKET_ERROR'
export const GETMYSERVICETICKET_SUCCESS = 'GETMYSERVICETICKET_SUCCESS'

// about
export const GETABOUTUS_REQUEST = 'GETABOUTUS_REQUEST'
export const GETABOUTUS_ERROR = 'GETABOUTUS_ERROR'
export const GETABOUTUS_SUCCESS = 'GETABOUTUS_SUCCESS'

// transition
export const GETTRANSLATION_REQUEST = 'GETTRANSLATION_REQUEST'
export const GETTRANSLATION_ERROR = 'GETTRANSLATION_ERROR'
export const GETTRANSLATION_SUCCESS = 'GETTRANSLATION_SUCCESS'

// contact
export const GETCONTACTUS_REQUEST = 'GETCONTACTUS_REQUEST'
export const GETCONTACTUS_ERROR = 'GETCONTACTUS_ERROR'
export const GETCONTACTUS_SUCCESS = 'GETCONTACTUS_SUCCESS'

export const SENTCONTACTUS_REQUEST = 'SENTCONTACTUS_REQUEST'
export const SENTCONTACTUS_ERROR = 'SENTCONTACTUS_ERROR'
export const SENTCONTACTUS_SUCCESS = 'SENTCONTACTUS_SUCCESS'

// product
export const GETPRODUCT_REQUEST = 'GETPRODUCT_REQUEST'
export const GETPRODUCT_ERROR = 'GETPRODUCT_ERROR'
export const GETPRODUCT_SUCCESS = 'GETPRODUCT_SUCCESS'

// product by id
export const GETPRODUCTBYID_REQUEST = 'GETPRODUCTBYID_REQUEST'
export const GETPRODUCTBYID_ERROR = 'GETPRODUCTBYID_ERROR'
export const GETPRODUCTBYID_SUCCESS = 'GETPRODUCTBYID_SUCCESS'

// product by category
export const GETPRODUCTBYCATEGORY_REQUEST = 'GETPRODUCTBYCATEGORY_REQUEST'
export const GETPRODUCTBYCATEGORY_ERROR = 'GETPRODUCTBYCATEGORY_ERROR'
export const GETPRODUCTBYCATEGORY_SUCCESS = 'GETPRODUCTBYCATEGORY_SUCCESS'

// product brand
export const GETPRODUCTBRAND_REQUEST = 'GETPRODUCTBRAND_REQUEST'
export const GETPRODUCTBRAND_ERROR = 'GETPRODUCTBRAND_ERROR'
export const GETPRODUCTBRAND_SUCCESS = 'GETPRODUCTBRAND_SUCCESS'

// promotion
export const GETPROMOTION_REQUEST = 'GETPROMOTION_REQUEST'
export const GETPROMOTION_ERROR = 'GETPROMOTION_ERROR'
export const GETPROMOTION_SUCCESS = 'GETPROMOTION_SUCCESS'

export const GETPROMODETAILLIST_REQUEST = 'GETPROMODETAILLIST_REQUEST'
export const GETPROMODETAILLIST_ERROR = 'GETPROMODETAILLIST_ERROR'
export const GETPROMODETAILLIST_SUCCESS = 'GETPROMODETAILLIST_SUCCESS'

// term & conditions && privacy policy
export const GETWEBSITEINFO_REQUEST = 'GETWEBSITEINFO_REQUEST'
export const GETWEBSITEINFO_ERROR = 'GETWEBSITEINFO_ERROR'
export const GETWEBSITEINFO_SUCCESS = 'GETWEBSITEINFO_SUCCESS'

// meta datas
export const GETMETADATA_REQUEST = 'GETMETADATA_REQUEST'
export const GETMETADATA_ERROR = 'GETMETADATA_ERROR'
export const GETMETADATA_SUCCESS = 'GETMETADATA_SUCCESS'

export const GETPAGE_REQUEST = 'GETPAGE_REQUEST'
export const GETPAGE_ERROR = 'GETPAGE_ERROR'
export const GETPAGE_SUCCESS = 'GETPAGE_SUCCESS'

// ads
export const GETADS_REQUEST = 'GETADS_REQUEST'
export const GETADS_ERROR = 'GETADS_ERROR'
export const GETADS_SUCCESS = 'GETADS_SUCCESS'

// banner
export const GETSLIDER_REQUEST = 'GETSLIDER_REQUEST'
export const GETSLIDER_ERROR = 'GETSLIDER_ERROR'
export const GETSLIDER_SUCCESS = 'GETSLIDER_SUCCESS'

// services
export const SENTEXPRESSSERVICE_REQUEST = 'SENTEXPRESSSERVICE_REQUEST'
export const SENTEXPRESSSERVICE_ERROR = 'SENTEXPRESSSERVICE_ERROR'
export const SENTEXPRESSSERVICE_SUCCESS = 'SENTEXPRESSSERVICE_SUCCESS'

export const GETEXPRESSSERVICE_REQUEST = 'GETEXPRESSSERVICE_REQUEST'
export const GETEXPRESSSERVICE_ERROR = 'GETEXPRESSSERVICE_ERROR'
export const GETEXPRESSSERVICE_SUCCESS = 'GETEXPRESSSERVICE_SUCCESS'

export const GETEXPRESSSERVICETYPE_REQUEST = 'GETEXPRESSSERVICETYPE_REQUEST'
export const GETEXPRESSSERVICETYPE_ERROR = 'GETEXPRESSSERVICETYPE_ERROR'
export const GETEXPRESSSERVICETYPE_SUCCESS = 'GETEXPRESSSERVICETYPE_SUCCESS'

export const SENTHOMESERVICE_REQUEST = 'SENTHOMESERVICE_REQUEST'
export const SENTHOMESERVICE_ERROR = 'SENTHOMESERVICE_ERROR'
export const SENTHOMESERVICE_SUCCESS = 'SENTHOMESERVICE_SUCCESS'

export const GETHOMESERVICE_REQUEST = 'GETHOMESERVICE_REQUEST'
export const GETHOMESERVICE_ERROR = 'GETHOMESERVICE_ERROR'
export const GETHOMESERVICE_SUCCESS = 'GETHOMESERVICE_SUCCESS'

export const GETHOMESERVICETYPE_REQUEST = 'GETHOMESERVICETYPE_REQUEST'
export const GETHOMESERVICETYPE_ERROR = 'GETHOMESERVICETYPE_ERROR'
export const GETHOMESERVICETYPE_SUCCESS = 'GETHOMESERVICETYPE_SUCCESS'

export const GETSERVICELOCATION_REQUEST = 'GETSERVICELOCATION_REQUEST'
export const GETSERVICELOCATION_ERROR = 'GETSERVICELOCATION_ERROR'
export const GETSERVICELOCATION_SUCCESS = 'GETSERVICELOCATION_SUCCESS'

export const GETSERVICESETTING_REQUEST = 'GETSERVICESETTING_REQUEST'
export const GETSERVICESETTING_ERROR = 'GETSERVICESETTING_ERROR'
export const GETSERVICESETTING_SUCCESS = 'GETSERVICESETTING_SUCCESS'

// website gallery detail
export const WEBSITEGALLERYDETAIL_REQUEST = 'WEBSITEGALLERYDETAIL_REQUEST'
export const WEBSITEGALLERYDETAIL_ERROR = 'WEBSITEGALLERYDETAIL_ERROR'
export const WEBSITEGALLERYDETAIL_SUCCESS = 'WEBSITEGALLERYDETAIL_SUCCESS'

export const GETLATESTPRODUCT_REQUEST = 'GETLATESTPRODUCT_REQUEST'
export const GETLATESTPRODUCT_ERROR = 'GETLATESTPRODUCT_ERROR'
export const GETLATESTPRODUCT_SUCCESS = 'GETLATESTPRODUCT_SUCCESS'

export const GETFEATUREDCATEGORY_REQUEST = 'GETFEATUREDCATEGORY_REQUEST'
export const GETFEATUREDCATEGORY_ERROR = 'GETFEATUREDCATEGORY_ERROR'
export const GETFEATUREDCATEGORY_SUCCESS = 'GETFEATUREDCATEGORY_SUCCESS'

export const GETfEATUREDPRODUCT_REQUEST = 'GETfEATUREDPRODUCT_REQUEST'
export const GETfEATUREDPRODUCT_ERROR = 'GETfEATUREDPRODUCT_ERROR'
export const GETfEATUREDPRODUCT_SUCCESS = 'GETfEATUREDPRODUCT_SUCCESS'

// home page content
export const GETHOMECONTENT_REQUEST = 'GETHOMECONTENT_REQUEST'
export const GETHOMECONTENT_ERROR = 'GETHOMECONTENT_ERROR'
export const GETHOMECONTENT_SUCCESS = 'GETHOMECONTENT_SUCCESS'